<template>
  <div class="gallery" v-if="!loading && data && data.length">
    <GalleryList :gallery="currentGallery" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useRoute } from "vue-router";
import GalleryList from "@/components/Gallery/GalleryList.vue";
import { galleryQuery } from "@/services/galleryQuery";

export default {
  name: "Gallery",
  setup() {
    const data = ref(null);
    const loading = ref(true);
    const error = ref(null);
    const route = useRoute();

    function fetchData() {
      loading.value = true;
      return fetch(
        "https://mclmgqhk.apicdn.sanity.io/v1/graphql/production/default",
        {
          method: "POST",
          body: JSON.stringify({ query: galleryQuery }),
          headers: {
            "content-type": "application/json"
          }
        }
      )
        .then(res => {
          if (!res.ok) {
            const error = new Error(res.statusText);
            error.json = res.json();
            throw error;
          }

          return res.json();
        })
        .then(json => {
          data.value = json.data.allGallery;
        })
        .catch(err => {
          error.value = err;
          if (err.json) {
            return err.json.then(json => {
              error.value.message = json.message;
            });
          }
        })
        .finally(() => {
          loading.value = false;
        });
    }

    const currentGallery = computed(() => {
      const currenctGallery = data.value.find(gallery => {
        return gallery.slug.current === route.params.tjeneste;
      });
      return currenctGallery;
    });

    onMounted(() => {
      fetchData();
    });

    return {
      data,
      loading,
      error,
      currentGallery
    };
  },
  components: { GalleryList }
};
</script>
<style lang="scss" scoped>
.gallery {
  margin: 0 auto;
  max-width: 1700px;
}
</style>
