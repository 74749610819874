export const galleryQuery = `{
    allGallery {
      title
      seoDescription
      Description
      slug {
        current
      }
      content {
        image {
          asset {
            url
            metadata {
              dimensions {
                height
                width
              }
              palette {
                lightMuted {
                  background
                }
              }
            }
          }
        }
      }
    }
  }
  `;
