<template>
  <div class="gallery-intro">
    <h2>{{ title }}</h2>
    <p>
      {{ description }}
    </p>
    <!--<button>Ta kontakt for pris</button>-->
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String
    },
    description: {
      type: String
    }
  }
};
</script>

<style scoped lang="scss">
button {
  border: 1px solid #fff;
  background: none;
  appearance: none;
  color: #fff;
  padding: 5px 10px;
  text-decoration: none;
  white-space: nowrap;
}

.gallery-intro {
  padding: 30px;
  background: #333642;
  color: #fff;
  font-size: 16px;
  font-weight: 400;
  border-radius: 3px;
}
</style>
