<template>
  <li class="gallery-item">
    <slot />
  </li>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
      required: true
    }
  }
};
</script>

<style scoped lang="scss">
.gallery-item {
  width: 150px;
  margin: 0 1rem 1rem 0;
  display: inline-block;
  width: 100%;
  text-align: center;
  font-family: system-ui;
  font-weight: 900;
  font-size: 2rem;
}
</style>
