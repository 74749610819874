import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Gallery from "../views/Gallery.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/tjenester/:tjeneste",
    name: "Galleri",
    component: Gallery
  },
  /*{
    path: "/kontakt",
    name: "Kontakt",
    component: () => import("../views/Contact.vue")
  },*/
  {
    path: "/",
    redirect: "/tjenester/portrett"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
