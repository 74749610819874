<template>
  <header>
    <router-link to="/" class="logo-link"><Logo class="logo"/></router-link>
    <Menu />
  </header>
</template>

<script>
import Menu from "@/components/Menu/Menu.vue";
import Logo from "@/components/Logo/Logo.vue";
export default {
  components: {
    Menu,
    Logo
  }
};
</script>

<style lang="scss" scoped>
header {
  text-align: center;
}
.logo {
  margin: 40px auto;
  max-width: 350px;
}

.logo-link {
  display: inline-block;
  max-width: 300px;
  width: 100%;
  border-bottom: none;
}
</style>
