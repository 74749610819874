<template>
  <Companylogo class="logo" alt="Anette Hovland fotograf" />
</template>

<script>
import Companylogo from "@/assets/fotograf-anette-hovland-logo-payload.svg";
export default {
  components: {
    Companylogo
  }
};
</script>

<style></style>
