<template>
  <nav id="nav">
    <router-link to="/tjenester/portrett">Portretter</router-link>
    <router-link to="/tjenester/nyfødt">Nyfødt</router-link>
    <router-link to="/tjenester/familie og barn">Familiefoto</router-link>
    <!--<router-link to="/kontakt">Kontakt</router-link>-->
  </nav>
</template>

<script>
export default {
  setup() {
    return {};
  },
  components: {}
};
</script>

<style lang="scss" scoped>
nav {
  margin: 40px auto;
  display: table;
}

.router-link-active {
  font-weight: bold;
}

a:link,
a:visited {
  text-transform: uppercase;
  margin: 0 10px;
  color: #333;
  text-decoration: none;
}

a:hover,
a:focus {
  border-bottom: 2px solid #333;
  outline: none;
}
</style>
