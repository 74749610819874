<template>
  <article :style="`background: ${metadata.palette.lightMuted.background}`">
    <img
      style=""
      :src="`${url}?auto=format`"
      alt=""
      :height="metadata.dimensions.height"
      :width="metadata.dimensions.width"
    />
  </article>
</template>

<script>
export default {
  props: {
    url: {
      type: String,
      default: "",
      required: true
    },
    metadata: {
      type: Object
    }
  }
};
</script>

<style scoped lang="scss">
img {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 3px;
}
</style>
