<template>
  <Header />
  <main>
    <router-view />
  </main>
  <Footer />
</template>

<script lang="ts">
import "sanitize.css";
import Header from "@/components/Header/Header.vue";
import Footer from "@/components/Footer/Footer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: { Header, Footer }
});
</script>

<style lang="scss">
body {
  font-family: proxima-nova, sans-serif;
  font-weight: 100;
  font-size: 16px;
  padding: 20px;
}

a:link,
a:visited {
  text-transform: uppercase;
  color: #333;
  text-decoration: none;
}

a:hover,
a:focus {
  border-bottom: 2px solid #333;
  outline: none;
}
</style>
