<template>
  <ul class="gallery-list">
    <GalleryItem>
      <GalleryIntro :title="gallery.title" :description="gallery.Description" />
    </GalleryItem>
    <GalleryItem v-for="image in gallery.content" :key="image">
      <GalleryImage
        :url="image.image.asset.url"
        :metadata="image.image.asset.metadata"
      />
    </GalleryItem>
  </ul>
</template>

<script>
import GalleryItem from "@/components/Gallery/GalleryItem.vue";
import GalleryImage from "@/components/Gallery/GalleryImage.vue";
import GalleryIntro from "@/components/Gallery/GalleryIntro.vue";
import { onMounted, onUnmounted, onUpdated } from "vue";
import Macy from "macy";

export default {
  name: "Gallery",
  props: {
    gallery: {
      type: Object
    }
  },
  setup() {
    let macy;
    const initMasonry = () => {
      macy = Macy({
        container: ".gallery-list",
        trueOrder: true,
        waitForImages: false,
        margin: 10,
        columns: 5,
        breakAt: {
          1550: 4,
          1370: 3,
          1050: 2,
          600: 1,
          400: 1
        }
      });
    };

    onMounted(() => {
      initMasonry();
    });

    onUpdated(() => {
      macy.runOnImageLoad(function() {
        macy.recalculate(true);
      }, true);
    });

    onUnmounted(() => {
      macy.remove();
    });

    return {};
  },
  components: { GalleryImage, GalleryItem, GalleryIntro }
};
</script>
<style lang="scss" scoped>
.gallery-list {
  justify-items: stretch;
  columns: 6;
  column-width: auto;
  column-fill: auto;
  scolumn-gap: 1rem;
  padding: 0;
}
</style>
