<template>
  <footer>
    <router-link to="/" class="footer-logo-link"
      ><Logo class="logo"
    /></router-link>
    <div>
      2020 © ANETTE HOVLAND |
      <a
        href="https://www.instagram.com/anetteshovland/"
        rel="noopener nofollow"
      >
        INSTAGRAM
      </a>
    </div>
  </footer>
</template>

<script>
import Logo from "@/components/Logo/Logo.vue";
export default {
  components: {
    Logo
  }
};
</script>

<style lang="scss" scoped>
footer {
  margin: 40px auto;
  display: table;
  font-size: 12px;
  color: #333;
  text-align: center;
}

a.footer-logo-link {
  display: block;
  opacity: 0.7;
  &:hover {
    border-bottom: none;
    opacity: 1;
  }
}

a:link {
  margin: 0;
}
</style>
